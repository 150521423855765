.tag-red{
    background-color: rgba(255, 72, 66, 0.16);
    color: rgb(183, 33, 54);
}
.tag-green{
background-color: rgba(84, 214, 44, 0.16);
color: rgb(34, 154, 22);
}
.tag-green, .tag-red{
    padding: 3px 10px;
    border-radius: 6px;
    height: 24px;
    font-weight: bold;
    width: fit-content;
    justify-content: center;
    align-items: center;
}

.languageselect{
    width: 220px !important;
    margin: 20px 20px 20px 0 !important;
}