body{
    font-family: 'Roboto',sans-serif;
}
.mainpage{
    height:100vh;
    width:100vw;
    display:flex;
    justify-content: center;
    background-color: hsl(205, 87%, 94%);
    align-items: center;
}
.login-window{
    width:500px;
    height:auto;
    background-color: white;
    border-radius:20px;
    padding:20px;
    border: 1px solid hsl(207, 89%, 90%);
}

h1{
    font-weight: 700;
    text-align: center;
    font-size: 1.5rem;
    color: #673ab7;
    font-family: 'Roboto',sans-serif;
}

h2{
    font-size: 0.875rem;
    font-weight: 500;
    color: #212121;
    font-family: 'Roboto',sans-serif;
    line-height: 1.75;
    text-align: center;
}
.input{
    margin-bottom:15px;
}
:global(.MuiFormControl-root){
    margin-bottom:20px;
}

.logo{
    display: flex;
    justify-content: center;
}


