@media screen and (max-width: 820px){
    h1, p, h2{
      width: 90% !important;
      text-align: center !important;
      margin: 0 !important;
    }
    .about{
      align-items: center !important;
    }
    p{
      font-size: 22px;
    }
    video{
      width: 90%;
      height: auto;
    }
  }
  @media screen and (max-width: 420px){
    h1, p, h2{
      width: 90% !important;
      text-align: center !important;
      margin: 0 0 10px 0!important;
    }
    .about{
      align-items: center !important;
    }
    p{
      font-size: 18px;
    }
  
    .landing h1,.landing h2{
      font-size: 2em !important;
    }
  
    .about p {
  width: 70% !important;
  font-size: 16px !important;
    }
    .octopus{
      width: 70px;
      height: auto;
    }
  
    header button{
      width: 100px;
    }
  
    video{
  width: 90%;
  height: auto;
    }
  }
  
  @media screen and (max-width: 1030px){
   .landing h1, .landing p, .landing h2{
      width: 90% !important;
      text-align: center !important;
      margin: 0 0 10px 0!important;
    }
  }
