.sidebar {
    width: 15%;
    height: 90vh;
}

.main {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: white;
}

.flex {
    display: flex;
    flex-direction: row;

}

.searchbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 30px 10px 30px;
    background-color: white;
}

.user {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.userName {
    color: grey;
    text-transform: none;
}

.torenderform {
    width: 80%;
    background-color: white;
    margin: auto;
    padding: 15px;
    border-radius: 20px;
}

.suit {
    background-color: hsl(244, 69%, 86%) !important;
}

form>button {
    background-color: #673ab7 !important;
}

.searchbar>button {
    background-color: white !important;
}

span {
    display: flex;
    justify-content: space-around;
}

.toolbar>h1 {
    text-align: left;
}

.toolbar {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.Mui-selected,
.css-e8broa-MuiButtonBase-root-MuiListItemButton-root:hover,
.css-e8broa-MuiButtonBase-root-MuiListItemButton-root.Mui-selected {
    border-radius: 10px !important;
    color: #5E35B1 !important;
    margin-left: 10px;
    margin-right: 10px;
}


.css-10hburv-MuiTypography-root:not(.MuiListItemText-primary) {
    padding: 0 10px 0 20px;
}

ul {
    padding: 0 !important;
}

.css-konndc-MuiListItemText-root {
    margin: 0 !important;
}

.Mui-selected .css-10hburv-MuiTypography-root {
    font-weight: bold !important;
}

.render {
    padding: 20px;
    background-color: hsl(203, 31%, 95%);
    border-Radius: 20px;
    margin: 10px;
    height: 100vh;
    overflow: auto;
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
    border: none !important;
}

.css-1o9fjio-MuiPaper-root-MuiAlert-root {
    border: 1px solid #c7e5fc;
}

.css-1osb3te-MuiButtonBase-root-MuiButton-root:hover {

    background-Color: #673ab7 !important;
}

.MuiFormLabel-root {
    display: inline-flex !important;
}

.searchBox {
    width: 100%;
    background-color: rgb(255, 255, 255);
    position: absolute;
    z-index: 9999;
    border-radius: 0 0px 10px 10px;
    box-shadow: 0px 4px 4px 0px rgb(208, 204, 204);
    margin-top: -4px;
}

.flexsearch{
    display: flex;
    justify-content: center;
    height: 50px;
    align-items: center;
}

#basic-menu .css-6hp17o-MuiList-root-MuiMenu-list{
    overflow: hidden !important;
}

