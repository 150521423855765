.post{
    background-color: white;
    border-radius: 5px;
    padding: 10px;
     width: auto;
     margin-bottom: 20px;
     
}

.flexBox{
    display: flex;
    flex-direction: row;   
}

/* hiding text editors imageupload button */
.noshow{
    display:none;
}

.postFile{
    width: 300px;
    margin:20px;
    height:auto;
    background-Color: white;
    padding: 15px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.css-1osb3te-MuiButtonBase-root-MuiButton-root:hover,
.css-1d2wf1k-MuiButtonBase-root-MuiButton-root:hover, 
.css-1akbjda-MuiButtonBase-root-MuiButton-root:hover{
    background-Color: #673ab7 !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list{
    max-height: 200px;
    overflow: auto;
}

.boxImg{
    border: 1px dashed grey;
    height: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; 
}

