.css-dmmspl-MuiFormGroup-root{
    align-content: flex-start;
}

form{
    display: flex;
flex-direction: column;
align-items: flex-start;
}

.css-13k226s-MuiFormControl-root{
display: flex !important;
flex-direction: row !important;
width: auto !important;
align-items: center !important;
justify-content: flex-start !important;
}


label{
    margin-right: 20px;
}
.select{
    justify-content: start;
}