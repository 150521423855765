.clickable{
cursor: pointer;

}

.clickable:hover{
  background-color:hsl(0, 13%, 91%);
  border-radius: 50%;
}

table {
    border-collapse: separate !important;
    border-spacing: 0 15px !important;
    border: none;
  }
  table, th{
    background-color: hsl(203, 31%, 95%);
  }
  th{
    border-bottom: none !important;
  }
  td{
    width: fit-content;
 border: none !important;
height: 30px;
  }

  
 th,td {
    text-align: center;
    padding: 5px;
    border: hidden;
  }
  td:first-of-type{
    border-radius: 10px 0 0 10px;

  }
  td:last-of-type{
    border-radius: 0 10px 10px 0;
  }
 
  .gridAvatar{
    justify-content: left;
  }

  .css-1t1j96h-MuiPaper-root-MuiDialog-paper{
    width: 80%;
  }