#form-file-upload {
    height: 20rem;
    width: 100%;
    max-width: 100%;
    text-align: center;
    position: relative;
  }

  
  .upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: 'Oswald', sans-serif;
    background-color: transparent;
  }
  
  .upload-button:hover {
    text-decoration-line: underline;
  }

  #drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 140px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  .cards{
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    min-height: 100px;
    width:100%;
    margin-top: 20px;

  }

  h5{
    margin-bottom: 0 !important;
  }

 .grid{
  width: 500;
   height: 20em;
   grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)) !important;
row-gap: 1em !important;
  } 

.item {
  object-fit: scale-down!important;
}


.file-upload{
  height: 10rem;
  width:100%;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.modal-item{
  max-height: 30rem;
  max-width: 40rem;
  object-fit: contain;
  margin: auto;
  padding-right: 20px;
}

.css-5y8tz6-MuiTypography-root{
  margin-bottom: 0 !important;
}
.absolute{
  position: absolute;
  right:20px;
  bottom: 10px;
}

 .gallerry{
  width: 98.5%;
}

