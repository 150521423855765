header{
    height: 50px;
    padding: 20px 40px 10px 40px;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

button{
    cursor: pointer;
    text-decoration: none;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    padding: 8px 22px;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    background-color: rgb(103, 58, 183);
    box-shadow: none;
    border: none;
}

.button:hover{
    background-color: black;
}
 .button a{
    text-decoration: none;
    color:white;
 }
